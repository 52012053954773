import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import Hero from "./screens/respiratory-health-test/hero"
import UnderHero from "./screens/respiratory-health-test/under-hero"
import SeriousTargets from "./screens/respiratory-health-test/serious-targets"
import Scales from "./screens/respiratory-health-test/scales"
import HowToUse from "./screens/respiratory-health-test/how-to-use"
import ProductDetails from "./screens/respiratory-health-test/product-details"
import Specifications from "./screens/respiratory-health-test/specifications"
import Downloads from "./screens/respiratory-health-test/downloads"

const SexualHealthTest = () => {
  return (
    <React.Fragment>
      <Hero />
      <UnderHero />
      <SeriousTargets />
      <Scales />
      <HowToUse />
      <ProductDetails />
      <Specifications />
      <Downloads />
    </React.Fragment>
  )
}
export default SexualHealthTest

export const Head = () => (
  <Seo
	  title="Respiratory Health Test — POC rapid PCR device"
	  description="Visby Medical's PCR Respiratory Health Test detects and differentiate RNA from the viruses that cause flu and COVID-19, in under 30 minutes with lab."
	  image="/meta/respiratory-health-test.jpg"
/>
)
